import { ErrorResponse, LoadingResponse, SuccessResponse } from '.'
import { DataResponse } from './types'

export const isLoading = (
  response: DataResponse<unknown>
): response is LoadingResponse => response.status === 'loading'

export const isError = (
  response: DataResponse<unknown>
): response is ErrorResponse => response.status === 'error'

export const isSuccess = <T>(
  response: DataResponse<T>
): response is SuccessResponse<T> => response.status === 'success'

export const isStringArray = (arr: unknown): arr is string[] => {
  return Array.isArray(arr) && arr.every((item) => typeof item === 'string')
}

import { useEffect, useRef } from 'react'
import { sendEvent } from '@lib/tracking'

/*
 ** This hook detects scroll thresholds, starting at 25%.
 ** When the user scrolls over the threshold, the threshold is increased by 25%.
 */

export const useScrollEvents = (pathname: string) => {
  const nextThreshold = useRef(1)

  useEffect(() => {
    const scrollStepsInPercents = 0.25

    const scrollEvents = pathname.includes('landing')
      ? ([
          'Scroll_25%_Landing',
          'Scroll_50%_Landing',
          'Scroll_75%_Landing',
          'Scroll_100%_Landing',
        ] as const)
      : ([
          'Scroll_25%_Homepage',
          'Scroll_50%_Homepage',
          'Scroll_75%_Homepage',
          'Scroll_100%_Homepage',
        ] as const)

    const pageHeight = document.body.scrollHeight - window.outerHeight

    const scrollStepInPixels = Math.round(pageHeight * scrollStepsInPercents)

    const isScrollOverThreshold = (scroll: number) =>
      scroll >= nextThreshold.current * scrollStepInPixels

    const handleScroll = () => {
      const scroll = window.scrollY

      // if the body height was calculated before the page was fully rendered,
      // the scroll step might be less than 1/4 of the page, meaning the user can scroll more than 4 scroll steps
      // thus, we cap the scroll threshold to 4
      while (isScrollOverThreshold(scroll) && nextThreshold.current < 5) {
        sendEvent({ event: scrollEvents[nextThreshold.current - 1], data: {} })

        nextThreshold.current = nextThreshold.current + 1
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [pathname])
}

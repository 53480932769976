import { isStringArray } from '@lib/api'

export const forcePageRefresh = (pathname: string) => {
  window.location.href =
    window.location.protocol + '//' + window.location.host + pathname
}

export const hasPopinBeenShown = () => {
  return window.localStorage.getItem('knxt-vip-popin-has-been-shown') === 'true'
}

export const setHasPopinBeenShown = () => {
  window.localStorage.setItem('knxt-vip-popin-has-been-shown', 'true')
}

export const getVIPNFTsFromQuery = (
  queryVIPNFTs: string | string[] | undefined
): string[] | [] => {
  if (isStringArray(queryVIPNFTs)) {
    return queryVIPNFTs
  }

  return typeof queryVIPNFTs === 'string' && queryVIPNFTs !== ''
    ? [queryVIPNFTs]
    : []
}

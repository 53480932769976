
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { InferGetStaticPropsType } from 'next'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { Layout, SEO } from '@components/common'
import { TextBanner } from '@components/common/TextBanner'
import { sendEvent } from '@lib/tracking'
import { getHomepageProducts, getMenuCategories } from '@lib/api'
import { ProductSection } from '@components/product/ProductSection'
import { Faq } from '@components/faq'
import { ButtonLink } from '@components/ui/ButtonLink'
import { getNewestFacetValueId } from '@lib/api/facet/getNewestFacetValueId'
import { NFTSection } from '@components/web3'
import { VIPPopin } from '@components/web3/VIPPopin'
import { getVIPNFTsFromQuery } from '@lib/helpers'
import { useScrollEvents } from '@lib/tracking/useScrollEvents'
import {
  AlexanderMcQueen,
  Balenciaga,
  BottegaVeneta,
  Courreges,
  Gucci,
} from '@components/icons'
import { PressCoverage } from '@components/press'
import { Logo } from '@components/ui'

 async function _getStaticProps() {
  const { menProducts, womenProducts, giftsProducts } =
    await getHomepageProducts()
  const menuCategories = await getMenuCategories()
  // const newestFacetValueId = await getNewestFacetValueId()

  return {
    props: {
      menProducts,
      womenProducts,
      giftsProducts,
      menuCategories,
      // newestFacetValueId,
    },
  }
}

export default function Home({
  menProducts,
  womenProducts,
  // newestFacetValueId,
  giftsProducts,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { t } = useTranslation()
  const router = useRouter()
  const vipNFTs = getVIPNFTsFromQuery(router.query.vipNFTs)
  const [showPopin, setShowPopin] = useState(false)
  useScrollEvents('/')

  useEffect(() => {
    if (router.query.isVIP === 'true') {
      setShowPopin(true)
    }
  }, [router.query.isVIP])

  return (
    <>
      <div className="background-image h-screen h-full flex items-center flex-col pt-[40px] px-6 sm:px-0 overflow-hidden">
        <div className="mb-[40px]">
          <Logo />
        </div>
        <div className="flex sm:items-center sm:justify-start justify-center container h-[70%] sm:h-[80%] relative">
          <div className="overflow-hidden h-[100%] sm:h-[100%] w-full z-10 relative">
            <Image
              src="/assets/under-construction.jpg"
              alt="under construction"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              // fill={true}
              // layout="fill"
              // objectFit="cover"
              width={1920}
              height={1080}
            />
          </div>
          <div className="text-caption absolute bottom-[-18%] sm:top-[40%] sm:left-[60px] sm:bottom-35 text-center sm:text-justify flex flex-col z-20">
            <h1 className=" text-4xl sm:text-6xl font-bold text-white mb-[20px] sm:mb-[30px]">
              We are <br /> enhancing your <br /> experience.
              {/* {t('home:title_part_3')} */}
            </h1>
            <p>Our site is currently under maintenance.</p>
            <p>Thank you for your patience.</p>
          </div>
        </div>
      </div>
      {/* <VIPPopin
        vipNFTs={vipNFTs}
        isShown={vipNFTs.length > 0 && showPopin}
        onClose={() => setShowPopin(false)}
      />
      <div className="relative mt-[-80px]">
        <div className="relative gradient-background-10">
          <div className="max-w-content-xl sm:mx-content-margin-desktop 2lg:relative 2xl:mx-auto lg:flex justify-between lg:h-[850px]">
            <div className="flex flex-col w-min mx-content-margin-mobile 2lg:mb-40 relative z-20 mt-20 sm:ml-0">
              <div className="lg:absolute lg:left-0 z-10 flex flex-col">
                <Transition
                  appear={true}
                  show={true}
                  as={Fragment}
                  enter="transition-all ease-out duration-1000 delay-100"
                  enterFrom="-translate-y-10 opacity-0"
                  enterTo="translate-y-0 opacity-100"
                >
                  <h1 className="mr-10 text-6xl sm:mt-24 sm:text-8xl font-archivo font-black text-blue object-cover">
                    {t('home:title_part_1')}
                    <br />
                    {t('home:title_part_2')}
                    <br />
                    {t('home:title_part_3')}
                  </h1>
                </Transition>
                <Transition
                  appear={true}
                  show={true}
                  as={Fragment}
                  enter="transition-all ease-out duration-1000 delay-300"
                  enterFrom="-translate-y-10 opacity-0"
                  enterTo="translate-y-0 opacity-100"
                >
                  <p className="mt-10 mb-7">
                    <Trans
                      i18nKey={'home:description_part_1'}
                      components={[<strong key={0} />]}
                    />
                    <br />
                    <Trans
                      i18nKey={'home:description_part_2'}
                      components={[<strong key={0} />]}
                    />
                    <br />
                    <Trans
                      i18nKey={'home:description_part_3'}
                      components={[<strong key={0} />]}
                    />
                  </p>
                </Transition>
                <Transition
                  appear={true}
                  show={true}
                  as={Fragment}
                  enter="transition-all ease-out duration-1000 delay-700"
                  enterFrom="-translate-y-10 opacity-0"
                  enterTo="translate-y-0 opacity-100"
                >
                  <div>
                    <ButtonLink
                      className="w-72 mb-4"
                      onClick={() => {
                        sendEvent({
                          event: 'Click_Category',
                          data: {
                            click_text: t('home:men_hero_section_button_label'),
                            page_path: window.location.pathname,
                            click_url: '/men/all',
                            button_id: 'home_men_collection_main_button',
                          },
                        })
                      }}
                      href={'/men/all'}
                      text={t('home:men_hero_section_button_label')}
                    />
                    <ButtonLink
                      className="w-72 mb-7"
                      onClick={() => {
                        sendEvent({
                          event: 'Click_Category',
                          data: {
                            click_text: t(
                              'home:women_hero_section_button_label'
                            ),
                            page_path: window.location.pathname,
                            click_url: '/women/all',
                            button_id: 'home_women_collection_main_button',
                          },
                        })
                      }}
                      href={'/women/all'}
                      text={t('home:women_hero_section_button_label')}
                    />
                    <a
                      href="#nft-section"
                      onClick={() => {
                        sendEvent({
                          event: 'Click_NFT_Information',
                          data: {},
                        })
                      }}
                      className="w-fit"
                    >
                      <Trans
                        i18nKey={'home:nft_anchor_link_text'}
                        components={[
                          <p key={0} />,
                          <span className="underline" key={1} />,
                        ]}
                      />
                    </a>
                  </div>
                </Transition>
              </div>
            </div>
            <Transition
              appear={true}
              show={true}
              as={Fragment}
              enter="transition-all ease-out duration-200"
              enterFrom="translate-x-32 opacity-0"
              enterTo="translate-x-0 opacity-100"
            >
              <div className="min-h-0 h-fit relative z-[5] pt-5 mt-auto">
                <video
                  src="/public/header_6.mp4"
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  className="h-auto lg:h-[50rem] w-auto border-fade"
                  poster="/public/hero-section-1.png"
                  playsInline={true}
                >
                  <track kind="captions" />
                </video>
              </div>
            </Transition>
          </div>
        </div>

        <TextBanner text={t('home:purple_banner_text')} />
        {process.env.NEXT_PUBLIC_IS_VALENTINES_ACTIVE === 'true' ? (
          <div className="bg-superDark/40 py-16">
            <ProductSection
              title={t('home:gifts_product_section_title')}
              productCategory="Gifts"
              buttonLabel={t('home:gifts_product_section_button_label')}
              products={giftsProducts}
              ctaURL="/gifts/all"
              newestFacetValueId={newestFacetValueId}
            />
          </div>
        ) : null}

        <div id="houses-section" className="relative bg-superDark/70">
          <div className="max-w-content-xl sm:mx-content-margin-desktop 2lg:relative 2xl:mx-auto 2lg:flex justify-between">
            <div className="flex flex-col mx-content-margin-mobile 2lg:mb-40 relative z-20 pt-20 sm:ml-0">
              <div className="2lg:absolute 2lg:left-0 z-10 flex flex-col sm:w-min">
                <h2 className="text-4xl font-archivo font-black text-blue sm:whitespace-nowrap">
                  {t('home:houses_section_title_part_1')}
                  <br />
                  {t('home:houses_section_title_part_2')}
                </h2>
                <Trans
                  i18nKey={'home:houses_section_text'}
                  components={[
                    <p className="mt-10 mb-8" key={0} />,
                    <strong key={1} />,
                  ]}
                />
                <div className="mx-auto sm:mx-0 sm:flex sm:space-x-12 sm:items-center w-fit">
                  <Gucci className="mx-auto mb-6 sm:mx-0" />
                  <Balenciaga className="mx-auto mb-6 sm:mx-0" />
                </div>
                <div className="mx-auto sm:mx-0 sm:flex sm:space-x-12 sm:items-center w-fit">
                  <BottegaVeneta className="mx-auto mb-6 sm:mx-0" />
                  <AlexanderMcQueen className="mx-auto mb-6 sm:mx-0" />
                </div>
                <div className="mx-auto sm:mx-0 sm:flex sm:space-x-12 sm:items-center w-fit">
                  <Courreges className="mx-auto sm:mx-0" />
                </div>

                <div className="mt-10">
                  <ButtonLink
                    className="w-72 mb-4"
                    onClick={() => {
                      sendEvent({
                        event: 'Click_Category',
                        data: {
                          click_text: t('home:men_hero_section_button_label'),
                          page_path: window.location.pathname,
                          click_url: '/men/all',
                          button_id: 'home_men_collection_main_button',
                        },
                      })
                    }}
                    href={'/men/all'}
                    text={t('home:men_hero_section_button_label')}
                  />
                  <ButtonLink
                    className="w-72 mb-7"
                    onClick={() => {
                      sendEvent({
                        event: 'Click_Category',
                        data: {
                          click_text: t('home:women_hero_section_button_label'),
                          page_path: window.location.pathname,
                          click_url: '/women/all',
                          button_id: 'home_women_collection_main_button',
                        },
                      })
                    }}
                    href={'/women/all'}
                    text={t('home:women_hero_section_button_label')}
                  />
                </div>
              </div>
            </div>
            <div className="min-h-0 w-full aspect-[683/653] h-auto relative z-[5] mt-auto narrow-border-fade lg:h-[662px] lg:w-[761px] mt-12">
              <div className="lg:h-full w-auto max-h-[90vh]">
                <Image
                  loading="lazy"
                  src="/public/landing-houses-section-1.png"
                  alt="owls dressed in Kering houses cloths"
                  fill
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-superDark/40 py-16">
          <ProductSection
            title={t('home:men_product_section_title')}
            buttonLabel={t('home:men_product_section_button_label')}
            productCategory="Men"
            products={menProducts}
            ctaURL="/men/all"
            newestFacetValueId={newestFacetValueId}
          />
        </div>
        <NFTSection
          cta={
            <div className="w-52 sm:w-72 mt-7 mb-4 mx-auto sm:mx-0 sm:mb-0">
              <ButtonLink
                className="w-full mt-4"
                href={'/faq#nft'}
                text={t('home:nft_section_button_label')}
                onClick={() => {
                  sendEvent({
                    event: 'Click_Home_NFT_Button',
                    data: {
                      click_text: t('home:nft_section_button_label'),
                      click_url: '/faq#nft',
                    },
                  })
                }}
              />
            </div>
          }
        />
        <div className="bg-superDark/40 py-16">
          <ProductSection
            title={t('home:women_product_section_title')}
            productCategory="Women"
            buttonLabel={t('home:women_product_section_button_label')}
            products={womenProducts}
            ctaURL="/women/all"
            newestFacetValueId={newestFacetValueId}
          />
        </div>
        <TextBanner text={t('home:purple_banner_text')} />
        <div className="bg-superDark/70">
          <PressCoverage />
        </div>
        <div className="max-w-content-xl sm:mx-content-margin-desktop 2lg:relative 2xl:mx-auto my-16">
          <Faq />
        </div>
      </div>
      <SEO
        title={t('home:meta_title')}
        description={t('home:meta_description')}
      /> */}
    </>
  )
}

// Home.Layout = Layout


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  